root{
  height: 100% !important;
  width: 100% !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100% !important;
  width: 100% !important;
  background-color: whitesmoke !important;
  color: black !important;
}

.btn{
  border-radius: 10px !important;
}

.form-control{
  border-radius: 10px !important;
  border-right: solid 1px lightgray !important;
  border-top: solid 1px lightgray !important;
  border-bottom: solid 1px lightgray !important;
}

.input-group-text{
  border-radius: 10px !important;
  border-left: solid 1px lightgray !important;
  border-top: solid 1px lightgray !important;
  border-bottom: solid 1px lightgray !important;
  background-color: white !important;
}


.signin-form .form-control{
  border-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-right: solid 1px lightgray !important;
  border-top: solid 1px lightgray !important;
  border-bottom: solid 1px lightgray !important;
}

.signin-form .input-group-text{
  border-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-left: solid 1px lightgray !important;
  border-top: solid 1px lightgray !important;
  border-bottom: solid 1px lightgray !important;
  background-color: white !important;
}





.list-group-item{
width: 100% !important;
border-bottom: solid 1px lightgray !important;
cursor:  pointer !important;
text-align: center !important;
}

.list-group-item:first-child{
  border-top: solid 1px white !important;
}


.react-datepicker{

  border: none !important;
}

.react-datepicker__month-container{

  border: none !important;
}

.react-datepicker__header{
  background-color: white !important;
  border: none !important;
}

.modal-header{
  border: none !important
}

.app-custom-richtext{
  border: solid 1px lightgray !important;
  border-radius: 10px !important;
}

.rounded{
  border-radius: 10px !important;
}

.css-b62m3t-container{
  width: 100% !important;
  margin-right: 10px !important;
}

.custom-quill-editor .ql-container {
  min-height: 203px !important;    
  max-height: 203px !important;    
  overflow-y: scroll !important;   
}

.custom-quill-editor .ql-editor {
  min-height: 203px !important;
}

.ql-toolbar{
  border: none !important;
  border-bottom: solid 1px lightgray !important;
}

.ql-container{
  border: none !important;
}

.css-t3ipsp-control{
  border-color: lightgray !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}


.css-b62m3t-container{
  border: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}

.css-13cymwt-control{
  border-radius: 10px !important;
}

.date-arrow{
  padding: 10px; 
  cursor: pointer !important;
background-color:  none !important;
  color: rgb(163, 163, 163) !important;
  font-weight: bolder !important;
  font-size: 2rem !important;
  border: none !important;
}


.item-container{
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
   width: 100% !important;


}

.btn-dark{
  background-color: #373737 !important;
  color: #fff !important;
  font: inherit;
cursor: pointer;
padding: 0.35rem 1rem;
border-radius: 10px;
}


.btn-red{
  background-color: #b81f1f !important;
  color: #fff !important;
  font: inherit;
cursor: pointer;
padding: 0.35rem 1rem;
border-radius: 10px;
}

.btn-green{
  background-color: #4a934a !important;
  color: #fff !important;
  font: inherit;
cursor: pointer;
padding: 0.35rem 1rem;
border-radius: 10px;
}


.btn-feedback{
  
  font: inherit;
cursor: pointer;
padding: 0.35rem 1rem;
border-radius: 10px;
}


.btn-task{
  background-color: whitesmoke ;
 padding: 10px !important;
 min-width: 45px !important;
 max-height: 45px !important;
 min-height: 45px !important;
 cursor: pointer !important;
 display: flex !important;
 border-radius: 10px !important;
 justify-content: center !important;
 align-items: center !important;
 border: solid 1px rgb(235, 235, 235) ;
}

.pill-task{
  padding: 3px !important;
  display: inline-block !important;
  color: gray !important;
  border: solid 1px rgb(235, 235, 235) ;
  background-color: whitesmoke ;
  border-radius: 10px !important;
  text-align: center !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.editor-html img{
max-width: 100% !important;
}

@media only screen and (max-width: 768px) {
  .btn-task{
    width: 100% !important;
    margin-top: 10px !important;
 
    max-height: 35px !important;
    min-height: 35px !important;
    font-size: 0.8rem !important;
  }
  .pill-task{
    width: 100% !important;
    margin-top: 10px !important;
  }
  .col-11{
    width: 100% !important;
  }
  .calendar-icon{
    padding-left: 11px !important;
    padding-right: 11px !important;
    margin-top: 6px !important;
  }
  .calendar-add{
    padding-right: 11px !important;
    padding-left: 11px !important;
  }
  .title-task{
    font-size: 1rem !important;
  }
  .calendar-select{
    padding-left: 11px !important;
    padding-right: 11px !important;
    margin-bottom: 8px !important;
  }

  
}


@media (max-width: 576px) {
  .btn-task{
    width: 100% !important;
    margin-top: 10px !important;
    max-height: 35px !important;
    min-height: 35px !important;
    font-size: 0.8rem !important;
  }
  .pill-task{
    width: 100% !important;
    margin-top: 10px !important;
    
  }
  .col-11{
    width: 100% !important;
  }
  .calendar-icon{
    padding-left: 11px !important;
    padding-right: 11px !important;
    margin-top: 6px !important;
  }
  .calendar-add{
    padding-right: 11px !important;
    padding-left: 11px !important;
  }
  .title-task{
    font-size: 1rem !important;
  }
  .calendar-select{
    padding-left: 11px !important;
    padding-right: 11px !important;
    margin-bottom: 8px !important;
  }
  
}

@media (max-width: 768px) {
  .btn-task,
  .pill-task {
    width: 100%;
    margin-top: 10px;
    min-height: 35px;
    max-height: 35px;
    font-size: 0.8rem;
  }

  .col-11 {
    width: 100%;
  }

  .calendar-icon,
  .calendar-add,
  .calendar-select {
    padding-left: 11px;
    padding-right: 11px;
    margin-top: 6px;
    margin-bottom: 8px;
  }

  .title-task {
    font-size: 1rem;
  }
}


.accordion{
  border-radius: 10px !important;

}

.accordion-item{
  border: none !important;
  border-radius: 10px !important;
  background-color: whitesmoke !important;
}

.accordion-button{
  border-radius: 10px !important;
}


.accordion-body{
  padding: 0px !important;
  padding-top: 10px !important;
}
.stats-bar{
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
border: solid 1px rgba(0,0,0,0.1);
  width: 100%;
  height: 60px;
}

.stats-bar-circle{
  height: 40px ;
  width: 40px;
  border-radius: 50%;
  background-color: lightgray;
  float: right;
  
}

.stats-bar-text{
  font-size: 1.0rem;
margin-top: 8px;
 
  float: right;
}

.stats-bar-title{
  font-size: 1.0rem;
margin-top: 8px;
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: block; 
float: left

}

.task-card-modern {
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin-bottom: 15px;
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.task-card-modern:hover {
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}


.task-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.task-info {
  display: flex;
  flex-direction: column;
}

.task-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.task-date {
  font-size: 14px;
  color: #777;
  margin-top: 5px;
}

.task-status {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}


.status-badge-modern {
  display: inline-flex;
  align-items: center;
  padding: 6px; 
  border-radius: 12px; 
  font-size: 12px; 
  font-weight: 500;
  color: white;
  transition: background-color 0.3s ease;
margin-left: 5px ;
}


.status-overdue { background-color: rgba(211, 37, 37, 0.85); }
.status-feedback { background-color: rgba(66, 135, 245, 0.85); }
.status-feedback-locked { background-color: rgba(136, 136, 136, 0.75); }
.status-completed { background-color: rgba(74, 147, 74, 0.85); }
.status-due-today { background-color: rgba(255, 153, 0, 0.85); }
.status-in-progress { background-color: rgba(136, 136, 136, 0.75); }
.status-locked { background-color: rgba(211, 37, 37, 0.85); }


.status-badge-modern:hover {
  opacity: 0.85; 
}


.task-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 10px;
}

.task-assigned {
  font-size: 13px;
  color: #777;
}

.task-finaldate {
  font-size: 13px;
  color: #777;
  text-align: right;
}


@media (max-width: 768px) {
  .task-content, .task-footer {
      flex-direction: column;
      align-items: flex-start;
  }

  .task-status, .task-finaldate {
      text-align: left;
      margin-top: 5px;
  }

  .task-title {
      font-size: 16px;
  }

  .status-badge-modern {
      font-size: 11px; 
      padding: 6; 
  }
}


.date-item{
    
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;

  padding: 15px !important;
border-radius: 10px !important;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
background-color: #ffffff !important;
transition: background-color 0.3s, box-shadow 0.3s !important;
  margin: 5px !important;
  min-width: 100px !important;
  cursor: pointer !important;
  flex-grow: 1 !important;
  text-align: center !important;
  transition: transform .2s !important;
}

.date-item:hover{
   background-color: #f9f9f9;
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15) !important;
}


.date-item:not(.selected) {
  background-color: white;
  border: none !important;
  color: black;
}

.offcanvas-body{
  padding: 0px !important;
}